import React from 'react';
import './Footer.css';

function Footer() {
	return (
		<div className="Footer">
			<p>Copyright Jackie Chan 2022</p>
		</div>
	);
}

export default Footer;
